<template>
  <div>
    <div class="row breadcrumbs-top">
      <div class="col-12">
        <h2 class="content-header-title float-left pr-1 mb-0; color: #d0d2d6 !important">
          <b-avatar
            size="30"
            src="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/03/BrimeLogoTransparentBW.png"
            variant="light-primary"
            class="badge-minimal"
          />
          {{ clipChannelStuff.replace(/^\w/, (c) => c.toUpperCase()) }}'s Clips
        </h2><div class="breadcrumb-wrapper">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link
                :to="`/${clipChannel}`"
              ><b>Return to Channel</b></router-link>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in orderBy(clips,'clipDate', -1)"
          :key="item._id"
          md="5"
          lg="4"
          xl="3"
        >
          <b-card
            v-b-modal.clipModal
            border-variant="primary"
            class="mb-3"
            @click="sendInfo(item)"
          ><span
             id="streamTitle"
             style="padding-bottom: 5px;"
           ><strong>{{ item.stream.category.name }}</strong></span><span><img
             v-if="item.clipThumbnailUrl"
             width="100%"
             :src="item.clipThumbnailUrl"
           ><img
             v-if="!item.clipThumbnailUrl"
             width="100%"
             src="https://content.brimecdn.com/brime/assets/missing_thumbnail.png"
           ><br></span>
            <span id="stream">
              <span id="streamtitle"><strong>{{ item.stream.title }}</strong></span>
              <span id="category">{{ item.clipName }}</span>
              <span
                id="clipDate"
                class="clipDate"
              >{{ getDate(item.clipDate) }}</span>
            </span>
            <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
          </b-card></b-col>
      </b-row>
    </section>
    <b-modal
      id="clipModal"
      ok-only
      ok-title="Close"
      button-size="sm"
      centered
      size="lg"
      title="Brime Clip ™"
      @hide="selectedClip=''"
    ><vue-plyr :options="options">
       <video
         controls
         crossorigin
         playsinline
         autoplay
       >
         <source
           size="1080"
           :src="selectedClip.clipVideoUrl"
           type="video/mp4"
         >
       </video>
     </vue-plyr><br>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="primary"
        :href="twitterShare + selectedClip._id"
        target="_blank"
      >
        Share On Twitter
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import {
  BRow, BCol, BCard, BModal, VBModal, BButton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vue2Filters from 'vue2-filters'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BCard,
    BModal,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      clips: '',
      clipChannel: '',
      clipChannelStuff: '',
      selectedClip: '',
      twitterShare: 'http://twitter.com/share?url=https://clips.brimelive.com/',
      options: {
        autoplay: true,
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'download',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
      },
    }
  },
  async mounted() {
    const channelStuff1 = this.$route.path
    const channelStuff2 = channelStuff1.replace('/', '')
    this.clipChannel = channelStuff2.replace('/clips', '')
    document.title = `${this.clipChannel}'s Clips - Brime`
    this.clipChannelStuff = this.clipChannel
    const reqURL = 'https://api-staging.brimelive.com/v1/channel/'
    const endpoint = '/clips'
    const clientId = '?client_id=605e4d6b3d79e12e5437985b'
    axios.get(reqURL + this.clipChannel + endpoint + clientId).then(response => {
      this.clips = response.data.data.clips
    })
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getDate(clipEpoch) {
      const d = new Date(clipEpoch * 1000)
      return d.toLocaleString()
    },
    sendInfo(item) {
      this.selectedClip = item
    },
  },
}
</script>
<style scoped>
.content-header-title{
color: #d0d2d6 !important;
margin-bottom: 20px;
font-size: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
/* Scrollbar styles */
.clipDate{
font-family: 'Roboto', sans-serif;
padding-top: 5px;
margin-bottom: -10px;
font-size: 14px;
}
.dark-layout .card {
  box-shadow: unset;
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(0.8);
  transition: all 0.2s;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  height: calc(100% - 3rem);
  display: flex;
}

.card-body {
  padding: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  color: #b4b7bd;
  margin-top: auto;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
#stream {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#streamTitle {
  color: #7367f0;
  font-size: 20px;
  display: block;
  text-align: center;
}
</style>
